import { render, staticRenderFns } from "./comision_individual.vue?vue&type=template&id=337c5cd3&"
import script from "./comision_individual.vue?vue&type=script&lang=js&"
export * from "./comision_individual.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports