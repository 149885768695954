<template>
  <div>
    <v-card-text>
      <div v-if="items.length == 0">
        <v-alert text type="info">No hay data para mostrar</v-alert>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="12" sm="3">
            <v-card outlined class="white pa-2">
              <v-list style="background: transparent !important">
                <v-list-item-group v-model="selected" color="primary">
                  <template v-for="(item, index) in items">
                    <v-list-item :key="index">
                      <v-list-item-content>
                        <v-list-item-title>{{
                          item.Telefono | phone
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.ProductName
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{
                          item.FechaActivacion | date
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      v-if="index < items.length - 1"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="9">
            <v-card outlined class="white pa-2">
              <v-card-title style="font-size: 15px !important">
                <v-row>
                  <v-col cols="10" sm="3">
                    <span
                      class="font-weight-bold pr-1"
                      style="font-weight: bold"
                      >{{ item.Telefono | phone }}</span
                    >
                    <br />
                    <span
                      style="color: #e4322b"
                      class="mr-10"
                      v-if="item.FechaCancelacion"
                      >Cancelado</span
                    >
                    <span style="color: #a6ce39" class="mr-10" v-else
                      >Activo</span
                    >
                  </v-col>
                  <v-col cols="12" sm="3">
                    <span class="font-weight-bold pr-1">{{
                      item.CodSucursal
                    }}</span>
                    <br />
                    <span>Sucursal</span>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="font-weight-bold pr-1">
                      {{ item.FechaActivacion | date }}</span
                    ><br />
                    <span>Fecha Activacion</span>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="font-weight-bold pr-1">
                      {{ item.EstadoActivacion }}</span
                    ><br />
                    <span>Estado</span>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <div class="text-right">
                      <crea-reclamacion-modal
                        v-if="validarAcceso('Reclamaciones')"
                        :activationId="item.ActivationId"
                        :telefono="item.Telefono"
                        :productCode="item.ProductCode"
                        :fechaActivacion="item.FechaActivacion"
                        :numeroOs="item.NumeroOS"
                        :productoTipoId="item.ProductoTipoId"
                        :productId="item.ProductID"
                        sistema="Externo"
                      ></crea-reclamacion-modal>
                    </div>
                  </v-col>
                </v-row>
              </v-card-title>
            </v-card>
            <br />
            <v-card outlined class="white pa-2">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="3">
                    <span>Tipo Producto</span>
                    <br />
                    <span class="font-weight-bold pr-1">{{
                      item.TipoProducto
                    }}</span>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <span>Producto</span>
                    <br />
                    <span class="font-weight-bold pr-1">{{
                      item.ProductName
                    }}</span>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <span>Price Plan</span>
                    <br />
                    <span class="font-weight-bold pr-1">{{
                      item.ProductCode
                    }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="3">
                    <span>Propiedad Equipo</span>
                    <br />
                    <span class="font-weight-bold pr-1">{{
                      validarEquipo(item.EquipoID)
                    }}</span>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <span>Contrato</span>
                    <br />
                    <span class="font-weight-bold pr-1">{{
                      item.ContratoEstado
                    }}</span>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <span>Comentario</span>
                    <br />
                    <span class="font-weight-bold pr-1">{{
                      item.ContratoComentario
                    }}</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <br />
            <v-card outlined class="white pa-2">
              <v-card-text>
                <v-progress-linear
                  color="primary"
                  indeterminate
                  height="6"
                  v-show="progressComision"
                ></v-progress-linear>

                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-row>
                        <v-col cols="12" sm="4"> <b> Aplicados</b></v-col>
                        <v-col cols="12" sm="4"
                          >Total:
                          <b> {{ totalMontoCorte | currency }}</b></v-col
                        >
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th class="text-left">CONDICION</th>
                            <th class="text-left">COMPONENTE</th>
                            <th class="text-left">FECHA</th>
                            <th class="text-left">PRODUCTO</th>
                            <th class="text-right">MONTO</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in detailAplicado" :key="item.Llave">
                            <td>{{ item.Origen }}</td>
                            <td>{{ item.Concepto }}</td>
                            <td>{{ item.Fecha | date }}</td>
                            <td>{{ item.ProductName }}</td>
                            <td class="text-right">{{ item.Monto }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-row>
                        <v-col cols="12" sm="4"> <b> Pendientes</b></v-col>
                        <v-col cols="12" sm="4"
                          >Total:
                          <b> {{ totalMontoPendiente | currency }}</b></v-col
                        >
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th class="text-left">CONDICION</th>
                            <th class="text-left">COMPONENTE</th>
                            <!-- <th class="text-left">PRODUCTO</th> -->
                            <th class="text-right">MONTO</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in detailPendiente" :key="item.Llave">
                            <td>{{ item.Origen }}</td>
                            <td>{{ item.Concepto }}</td>
                            <!-- <td>{{ item.ProductName }}</td> -->
                            <td class="text-right">{{ item.Monto }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <b> No Aplican</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th class="text-left">COMPONENTE</th>
                            <!-- <th class="text-left">PRODUCTO</th> -->
                            <th class="text-left">MOTIVO</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in detailNoPaga" :key="item.Llave">
                            <td>{{ item.Concepto }}</td>
                            <!-- <td>{{ item.ProductName }}</td> -->
                            <td>{{ item.DescMotivoNopago }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-row>
                        <v-col cols="12" sm="4"> <b> Incentivos</b></v-col>
                        <v-col cols="12" sm="4"
                          >Total: <b> {{ totalIncentivo | currency }}</b></v-col
                        >
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th class="text-left">CONDICION</th>
                            <th class="text-left">COMPONENTE</th>
                            <th class="text-left">FECHA</th>
                            <!-- <th class="text-left">PRODUCTO</th> -->
                            <th class="text-right">MONTO</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in detailIncentivo" :key="item.Llave">
                            <td>{{ item.Origen }}</td>
                            <td>{{ item.Incentivo }}</td>
                            <td>{{ item.Fecha | date }}</td>
                            <!-- <td>{{ item.ProductName }}</td> -->
                            <td class="text-right">{{ item.Monto }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table></v-expansion-panel-content
                    >
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        color="transparent"
        absolute
        top
        right
        fixed
        style="top: 120px; right: 0px"
        @click.native="drawerRight = !drawerRight"
      >
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-spacer></v-spacer>
        <v-divider></v-divider>

        <br />
        <v-card-text style="padding: 0px !important">
          <v-text-field
            v-model="search"
            clearable
            hide-details
            placeholder="Teléfono, Número de Orden, Serie (IMEI), Simcard"
            v-on:keyup.13="get"
            :rules="busquedaValidation"
            ref="search"
            :loading="loadingSearch"
            @click:append="get"
          ></v-text-field>
          <br />
          <div class="mb-5 text-right">
            <v-btn block class="mb-1" depressed color="primary" @click="get">
              <v-icon left>search</v-icon>Buscar
              <span slot="loader">Buscando...</span>
            </v-btn>
            <v-btn
              block
              depressed
              :loading="loadingDownload"
              @click="download"
              :disabled="items.length == 0"
            >
              Exportar
            </v-btn>
          </div>
        </v-card-text>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import apiActivacion from "@/api/activaciones";
import apiComision from "@/api/comisiones";
import CreaReclamacionModal from "@/components/reclamacion/reclamacion_creacion";
import { mapActions } from "vuex";
import download from "downloadjs";

export default {
  components: {
    CreaReclamacionModal,
  },
  data() {
    return {
      drawerRight: true,
      search: "",
      right: true,
      item: null,
      selected: null,
      progressComision: false,
      loadingSearch: false,
      loadingDownload: false,
      items: [],
      detail: [],
      detailAplicado: [],
      totalMontoCorte: 0,
      detailPendiente: [],
      totalMontoPendiente: 0,
      detailNoPaga: [],
      detailIncentivo: [],
      totalIncentivo: 0,
      busquedaValidation: [
        (v) => !!v || "Debe digitar un criterio de busqueda",
      ],
    };
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    get() {
      if (this.search) {
        var str = this.search.replace(/\s/g, "").replace("-", "");
        this.expand = false;
        this.search = str;
        this.loadingSearch = true;
        this.items = [];
        this.detail = null;
        this.detailAplicado = [];
        this.detailPendiente = [];
        this.detailNoPaga = [];
        this.selected = null;

        apiActivacion
          .getActivacion(this.search)
          .then((response) => {
            if (response.data.length > 0) {
              this.items = response.data;
              this.selected = 0;
            }
            
            this.loadingSearch = false;
          })
          .catch((error) => {
            this.setError(error);
            this.loadingSearch = false;
          });
      }
    },
    download() {
      this.loadingDownload = true;
      apiComision
        .getComisionActivacionMulti(this.items)
        .then((response) => {
          download(response.data, "ResultadosIndividual" + ".xlsx");
          this.loadingDownload = false;
        })
        .catch((error) => {
          this.setError(error);
          this.loadingDownload = false;
        });
    },
    split(data) {
      this.detailAplicado = data.ListaAplicados;
      this.detailIncentivo = data.ListaIncentivos;
      this.detailPendiente = data.ListaPendientes;
      this.totalMontoPendiente = data.TotalPendientes;
      this.totalMontoCorte = data.TotalAplicados;
      this.totalIncentivo = data.TotalIncentivos;
      this.detailNoPaga = data.ListaNoAplican;
    },
    getDetail(record) {
      this.progressComision = true;
      apiComision
        .getComisionActivacion(record.ActivationID)
        .then((response) => {
          this.detail = response.data;
          this.split(this.detail);
          this.progressComision = false;
        })
        .catch((error) => {
          this.setError(error);
          this.progressComision = false;
        });
    },
    validarEquipo(equipoid) {
      if (equipoid === null) {
        return "Propiedad Cliente";
      } else {
        return "Equipo Claro";
      }
    },
    validarAcceso(accion) {
      var acceso = {};
      try {
        acceso = this.currentUser.accesos.find((c) => c.title === accion);
      } catch (error) {
        acceso = undefined;
      }
      return acceso !== undefined || accion === "Default";
    },
  },
  computed: {
    loading() {
      return this.loadingSearch || this.loadingDownload;
    },
    currentUser() {
      return this.$store.getters.user;
    },
  },
  watch: {
    selected: function () {
      if (this.selected != undefined) {
        this.item = this.items[this.selected];
        this.getDetail(this.item);
      } else {
        this.item = null;
      }
    },
  },
  mounted() {
    // // this.$store.dispatch("setBreadcrumbs", this.breadcrumb);
    this.detail = null;
  },
};
</script>