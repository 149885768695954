import { global } from './global'
import { store } from '@/store'

let _baseurl = global.baseApiUrl + 'activacion/'

export default {
  getActivacion(registro) {
    return global.axios.get(_baseurl + 'get-activacion-por-telefono-orden?registro=' + registro
      + '&asociadoid=' + store.getters.user.asociado.id
      + '&canalasociadoid=' + store.getters.user.asociado.canalAsociadoId
      + '&canalid=' + store.getters.user.canalid)
  },
  getEstadoContrato(activationId) {
    return global.axios.get(_baseurl + 'get-estado-contrato?activationid=' + activationId)
  },
  getEstados() {
    return global.axios.get(_baseurl + 'get-estados')
  },
  getActivacionIndividual(param) {
    return global.axios.post(_baseurl + 'get-activaciones-individual', param)
  },
  getActivacionMasiva(param) {
    return global.axios.post(_baseurl + 'get-activaciones-masiva', param, { responseType: 'blob', headers: { 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/json' } })
  },
  getActivacionMasivaParametros(param) {
    return global.axios.post(_baseurl + 'get-activaciones-masiva-parametros', param, { responseType: 'blob', headers: { 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/json' } })
  },
}
