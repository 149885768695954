<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn  text depressed color="error" v-on="on"
        >Reclamar</v-btn
      >
    </template>

    <v-card>
      <v-card-title>
        <span>Nueva Reclamación</span>
      </v-card-title>
      <v-card-text>
        <v-row wrap>
          <v-col cols="12" sm="4">
            Teléfono <br />
            <span class="font-weight-bold">{{ telefono }}</span>
          </v-col>
          <v-col cols="12" sm="4">
            Price Plan <br />
            <span class="font-weight-bold">{{ productCode }}</span>
          </v-col>
          <v-col cols="12" sm="4">
            Fecha <br /><span class="font-weight-bold"
              >{{ fechaActivacion | date }}
            </span>
          </v-col>
        </v-row>
        <br />
        <v-autocomplete
          v-model="motivo"
          label="Motivo reclamación"
          required
          :items="listaMotivos"
          append-icon="keyboard_arrow_down"
        ></v-autocomplete>
        <v-autocomplete
          v-model="concepto"
          label="Componente/Incentivo reclamado"
          required
          multiple
          :items="listaComponentes"
          item-value="Nombre"
          item-text="Nombre"
          append-icon="keyboard_arrow_down"
        ></v-autocomplete>
        <v-textarea
          outline
          v-model="comentario"
          label="Comentario"
          required
        ></v-textarea>
        <v-row row wrap ml-2>
          <v-col xs12>
            <v-text-field
              placeholder="Seleccionar anexos"
              @click="pickFile"
              v-model="fileNombre"
              prepend-icon="attach_file"
            ></v-text-field>
            <input
              type="file"
              style="display: none"
              ref="excel"
              accept="*"
              @change="onFilePicked"
              multiple
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          
          
          depressed
          color="primary"
          @click.stop="save"
          :disabled="bloque"
          >Reclamar</v-btn
        >
        <v-btn   text depressed color="primary" @click.stop="clear"
          >Cancelar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apiReclamacion from "@/api/reclamaciones";
import apiComision from "@/api/comisiones";
import { mapActions } from "vuex";

export default {
  props: [
    "activationId",
    "telefono",
    "productCode",
    "fechaActivacion",
    "serie",
    "numeroOs",
    "productoTipoId",
    "productId",
    "sistema",
    "pdialog",
  ],
  watch: {
    productId: {
      handler: function (newProps) {
        if (newProps) {
          if (this.sistema === "Externo") {
            apiComision
              .getComponentesByProducto(newProps)
              .then((response) => {
                this.listaComponentes = response.data;
                let compOtros = {
                  ProductoComisionComponentId: 0,
                  Nombre: "Otros",
                };
                this.listaComponentes.push(compOtros);
              })
              .catch((error) => {
                this.setError(error);
              });
          } else {
            apiComision
              .getComponentesIncentivosByCanal(this.user.canalid,this.user?.asociado?.asociadoComisionGroupID)
              .then((response) => {
                this.listaComponentes = response.data;
                let compOtros = {
                  Nombre: "Otros",
                };
                this.listaComponentes.push(compOtros);
              })
              .catch((error) => {
                this.setError(error);
              });
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      dialog: false,
      bloque: false,
      comentario: "",
      motivo: "",
      concepto: "",
      fileNombre: "",
      fileUrl: "",
      files: null,
      listaMotivos: [
        "Comisión no pagada",
        "Error en comisión",
        "Falta comisión",
        "Otros (especifique)",
      ],
      listaComponentes: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    clear() {
      this.comentario = "";
      this.motivo = "";
      this.concepto = "";
      this.dialog = false;
      this.files = null;
    },
    save() {
      this.bloque = true;

      console.log(this.user.asociado.canalAsociadoId);
      console.log(this.user.empleadoid);
      if (!(this.user.asociado.canalAsociadoId || this.user.empleadoid)) {
        this.clear();
        return;
      }

      let newRecord = {
        CanalAsociadoId: this.user.asociado.canalAsociadoId,
        EmpleadoId: this.user.empleadoid,
        CanalId: this.user.canalid,
        Sistema: this.sistema,
        Telefono: this.telefono,
        ProductCode: this.productCode,
        FechaActivacion: this.fechaActivacion,
        Serie: null,
        NumeroOs: this.numeroOs,
        ActivationId: this.activationId,
        Concepto: this.concepto,
        Motivo: this.motivo,
        Comentario: this.comentario,
        Usuario: this.user.username,
        ReclamacionTipoCodigo:"RECLAMACION_ACTIVACION"
      };
      let data = new FormData();
      data.append("reclamacion", JSON.stringify(newRecord));
      for (var key in this.files) {
        data.append("Archivos[]", this.files[key]);
      }

      apiReclamacion
        .addReclamacion(data)
        .then((response) => {
          newRecord.ReclamacionId = response.data.ReclamacionId;
          newRecord.Numero = response.data.Numero;
          let mensaje = {
            title: "Reclamación",
            body:
              "Se ha creado satisfactoriamente la reclamación número " +
              newRecord.Numero,
            type: "confirm",
          };
          this.setMessage(mensaje);
        })
        .catch((error) => {
          this.setError(error);
        })
        .finally(() => {
          this.bloque = false;
          this.clear();
        });
    },
    pickFile() {
      this.$refs.excel.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.fileNombre = "";
        for (var i = 0, l = files.length; i < l; i++) {
          var item = files[i];
          this.fileNombre +=
            this.fileNombre.length === 0 ? item.name : ", " + item.name;
        }
        this.files = files;
        if (this.fileNombre.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.fileUrl = fr.result;
          // this.file = files[0] // this is a file that can be sent to server...
        });
      } else {
        this.fileNombre = "";
        this.files = null;
        this.fileUrl = "";
      }
    },
  },
};
</script>

<style>
</style>
